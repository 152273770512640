import styled from "styled-components";

import { radius } from "../../constants/variables";

const BoxShadowImage = styled.img`
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);
  border-radius: ${radius};
`;

export default BoxShadowImage;
