import React from "react";
import styled from "styled-components";

import {
  device,
  colors,
  paddingSmall,
  paddingMedium,
  radius,
} from "../../constants/variables";

import BoxShadow from "../shared/BoxShadow";
import ButtonWithIcon from "../shared/ButtonWithIcon";
import Panel from "../shared/Panel";
import Title from "../shared/Title";

import image from "../../assets/images/members.jpg";
import downloadIcon from "../../assets/images/circle-down.svg";

const BecomeMember = () => {
  return (
    <section id="clanstvo">
      <StyledPanel backgroundColor={colors.white}>
        <StyledBoxShadow>
          <Content>
            <Title light>Postani član</Title>
            <Text>
              Izpolnite prijavnici in nam jih pošljite na
              sah.ljubljana@gmail.com in se pridružite najboljšim šahistom v
              Sloveniji!
            </Text>
            <ButtonsWrapper>
              <a
                href={process.env.PUBLIC_URL + "/files/pristopnica.rtf"}
                download
              >
                <StyledButtonWithIconRight light icon={downloadIcon}>
                  Prijavnica 
                </StyledButtonWithIconRight>
              </a>
            </ButtonsWrapper>
          </Content>
          <Image src={image}></Image>
        </StyledBoxShadow>
      </StyledPanel>
    </section>
  );
};

const StyledPanel = styled(Panel)`
  padding: 0;
  @media ${device.mobile} {
    background-color: ${colors.primary};
    padding: ${paddingMedium} 0;
  }
`;

const StyledBoxShadow = styled(BoxShadow)`
  display: flex;
  background-color: ${colors.primary};
  color: ${colors.white};

  @media ${device.mobile} {
    flex-direction: column;
    box-shadow: none;
  }
`;

const Content = styled.div`
  padding: ${paddingMedium};
  flex: 2;

  @media ${device.mobile} {
    padding: 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;

    button {
      max-width: 400px;
    }
  }
`;

const StyledButtonWithIcon = styled(ButtonWithIcon)`
  @media ${device.mobile} {
    width: 100%;
    max-width: 400px;
  }
`;

const StyledButtonWithIconRight = styled(StyledButtonWithIcon)`

  @media ${device.mobile} {
    margin-top: 20px;
    margin-left: 0;
  }
`;

const Image = styled.img`
  width: 400px;
  object-fit: cover;
  max-height: 500px;

  border-radius: 0 ${radius} ${radius} 0;

  @media ${device.mobile} {
    margin-top: ${paddingSmall};
    margin-right: 0;
    border-radius: ${radius};
    box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);
    margin-top: ${paddingSmall};
    margin-right: 0;
    width: 100%;
    max-width: 400px;
  }
`;

const Text = styled.div`
  margin-bottom: ${paddingSmall};
`;

export default BecomeMember;
