import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

import {
  device,
  contentWidth,
  navigationHeight,
  colors,
} from "../../constants/variables";

import Logo from "./Logo";

const Navigation = ({ scrolled }) => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <Wrapper scrolled={scrolled}>
      <Content>
        <HashLink to="/#domov" smooth>
          <StyledLogo scrolled={scrolled} />
        </HashLink>
        <LinksWrapper scrolled={scrolled}>
          <Link
            to="/#ekipa"
            smooth
            scrolled={scrolled}
            scroll={(el) => scrollWidthOffset(el)}
          >
            Ekipa
          </Link>
          <Link
            to="/#webinarji"
            smooth
            scrolled={scrolled}
            scroll={(el) => scrollWidthOffset(el)}
          >
            Webinarji
          </Link>
          <Link
            to="/#turnirji"
            smooth
            scrolled={scrolled}
            scroll={(el) => scrollWidthOffset(el)}
          >
            Turnirji
          </Link>
          <Link
            to="/#clanstvo"
            smooth
            scrolled={scrolled}
            scroll={(el) => scrollWidthOffset(el)}
          >
            Postani član
          </Link>
          <Link
            to="/#sola"
            smooth
            scrolled={scrolled}
            scroll={(el) => scrollWidthOffset(el)}
          >
            Šahovska šola
          </Link>
          <Link
            to="/#kontakt"
            smooth
            scrolled={scrolled}
            scroll={(el) => scrollWidthOffset(el)}
          >
            Kontakt
          </Link>
        </LinksWrapper>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  position: fixed;
  transition: 0.4s ease-out;

  color: ${({ scrolled }) => (scrolled ? colors.text : colors.black)};
  background-color: ${({ scrolled }) =>
    scrolled ? colors.white : "transparent"};

  border-bottom: ${({ scrolled }) =>
    scrolled ? "1px solid " + colors.white : "none"};

  height: ${({ scrolled }) => (scrolled ? navigationHeight : "120px")};

  box-shadow: ${({ scrolled }) =>
    scrolled ? "5px 5px 18px rgb(27, 5, 107, 0.1)" : "none"};

  @media ${device.mobile} {
    display: none;
  }
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: auto;
  @media ${device.mobile} {
    margin: auto;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const StyledLogo = styled(Logo)`
  width: 80px;
  margin-top: 6px;
  transform: ${({ scrolled }) => scrolled && "scale(0.7) translate(-10%, 0%)"};
  transition: 0.4s ease-out;
`;

const LinksWrapper = styled.div`
  display: flex;

  div {
  }
`;

const Link = styled(HashLink)`
  font-weight: 700;
  font-size: 14px;
  color: ${({ scrolled }) => (scrolled ? colors.black : colors.white)};
  &:hover {
    color: ${colors.primary};
  }
  cursor: pointer;
  transition: 0.4s ease-out;
  @media ${device.mobile} {
    display: none;
  }
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

export default Navigation;
