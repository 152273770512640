import React, { useState, useEffect } from "react";
import styled from "styled-components";

import BecomeMember from "./BecomeMember";
import ClubOffice from "./ClubOffice";
import Cover from "./Cover/";
import FacebookLink from "./FacebookLink";
import Footer from "./Footer";
import JuniorTeam from "./JuniorTeam";
import MemberPerks from "./MemberPerks";
import Navigation from "./Navigation/";
import NavigationMobile from "./Navigation/NavigationMobile";
import School from "./School";
import Sponsors from "./Sponsors";
import Quotes from "./Quotes";
import Team from "./Team";
import Tournaments from "./Tournaments";
import Webinars from "./Webinars";

const Home = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 120;
      if (show) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Wrapper className="App" id="outer-container">
      <NavigationMobile
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
        scrolled={scrolled ? true : false}
      />
      <div id="page-wrap">
        <Navigation scrolled={scrolled ? true : false} />
        <Cover />
        <Team />
        <JuniorTeam />
        <Webinars />
        <Tournaments />
        <BecomeMember />
        <MemberPerks />
        <Quotes />
        <School />
        <Sponsors />
        <ClubOffice />
        <Footer />
        <FacebookLink />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

export default Home;
