import React from "react";
import styled from "styled-components";

import { contentWidthNarrow, colors, radius } from "../../constants/variables";

import crown from "../../assets/images/crown.svg";

import BoxShadow from "../shared/BoxShadow";
import BoxShadowImage from "../shared/BoxShadowImage";
import Button from "../shared/Button";

const TeamMember = ({ member }) => {
  return (
    <PaddingWrapper>
      <Wrapper>
        <StyledBoxShadowImage src={member.image} />
        <Prefix>{member.prefix}</Prefix>
        <Name>{member.name}</Name>
        <Description>{member.description}</Description>
        {member.link && (
          <Link>
            <a
              href={"https://www.twitch.tv/checkitas"}
              target="_blank"
              rel="noreferrer"
            >
              <IconWrapper>
                <Icon src={crown} />
              </IconWrapper>
            </a>
          </Link>
        )}
        {member.link && (
          <a
            href={"https://www.twitch.tv/checkitas"}
            target="_blank"
            rel="noreferrer"
          >
            <StyledButton>Twitch kanal</StyledButton>
          </a>
        )}
      </Wrapper>
    </PaddingWrapper>
  );
};

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const Prefix = styled.div`
  text-align: center;
  max-width: ${contentWidthNarrow};
  color: ${colors.primary};
  font-size: 20px;
  font-weight: 900;
`;

const Name = styled.div`
  margin-bottom: 16px;
  text-align: center;
  max-width: ${contentWidthNarrow};
  color: ${colors.primary};
  font-size: 20px;
  font-weight: 900;
`;

const Description = styled.div`
  font-size: 12px;
  text-align: center;
  line-height: 1.8;
`;

const StyledBoxShadowImage = styled(BoxShadowImage)`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 75px;
  position: absolute;
  top: -75px;
  left: 75px;
  transition: 0.2s ease-out;
  object-position: 30% 30%;
`;

const PaddingWrapper = styled.div`
  padding: 0 12px;
  display: flex;
`;

const Wrapper = styled.div`
  width: 300px;
  background-color: ${colors.white};
  margin-top: 75px;
  margin-bottom: 40px;
  padding: 95px 28px 28px 28px;
  position: relative;
  text-align: center;

  border-radius: ${radius};
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);
  transition: 0.4s ease-out;

  &:hover {
    background-color: ${colors.primary};
    box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.18);
    transform: scale(1.06);

    ${Name}, ${Description} {
      color: ${colors.white};
    }

    ${StyledBoxShadowImage} {
      object-position: 40% 40%;
    }

    ${StyledButton} {
      color: ${colors.primary};
      background-color: ${colors.white};
    }
  }
`;

const Link = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
`;

const IconWrapper = styled(BoxShadow)`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${colors.primaryLight};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${colors.primaryLightHovered};
  }
`;

const Icon = styled.img`
  width: 30px;
`;

export default TeamMember;
