import React from "react";
import styled from "styled-components";

import { colors } from "../../constants/variables";
import BoxShadow from "../shared/BoxShadow";

import facebook from "../../assets/images/logo-facebook.svg";

const FacebookLink = () => {
  return (
    <a
      href={
        "https://www.facebook.com/Tajfun-%C5%A0K-Ljubljana-658674184243082/"
      }
      target="_blank"
      rel="noreferrer"
    >
      <Wrapper>
        <Image src={facebook} />
      </Wrapper>
    </a>
  );
};

const Wrapper = styled(BoxShadow)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: ${colors.primary};
  height: 70px;
  width: 70px;
  z-index: 5;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  display: none;

  &:hover {
    background-color: ${colors.primaryDark};
  }
`;

const Image = styled.img`
  height: 40px;
`;

export default FacebookLink;
