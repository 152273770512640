import React from "react";

const Logo = ({ scrolled, className }) => {
  const color = scrolled ? "#000000" : "#FFFFFF";
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 361.27 413.26"
    >
      <g fill={color}>
        <g>
          <path d="M166.57,33.69l-2.72-11.17c-3.52-0.57-7.26-0.99-11.18-1.26l3.92,12.43H166.57z" />
          <path d="M208.6,21.26c-3.92,0.27-7.65,0.69-11.18,1.26l-2.72,11.17h9.98L208.6,21.26z" />
          <path
            d="M139.45,186.29h0.46v-3v-7.55V171h4.76h0.66v-1.87v-6.54v-4.11h4.11h3.91l11.02-92.72h-10.84h-3.82v-7.62h3.82h2.02
			l-19-37.02c-7.79,0.38-16.17,1.4-25.19,3.27c0,0,13.24,3.86,21.7,21.08c-4.55-1.5-8.78-1.82-12.79-1.36l5.01-6.34
			c0,0-15.03-7.29-38.76,3.87c0,0,10.55-10-1.54-27.46h-0.02c-0.12-0.01-5.51,17.71-18.57,25.24C53.19,47.02,41,56.29,45.11,68.24
			c0,0-2.14,5-13.53,10.55c-8.42,4.1-17.71,6.16-17.41,10.78c0.88,13.19,22.71,21.09,22.71,21.09s0.31-11.24,8.8-7.84
			c0,0-4.4-16.81,22.21-20.33c0,0,6.58,9.22-1.19,21.05c-6.19,9.41-11.01,11.86-15.95,10.69c0,0-0.88,9.8,11.81,10.03
			c12.69,0.23,20.56,1.23,20.56,1.23s-5.57-6.11,0.13-20.49c3.71-9.37,13.63-9.89,14.49-22.31c0,0,7.44,3.46,7.73,16.61
			c0.21,9.48-3.06,15.96-6.97,22.33c-10.49,14.77-38.15,27.3-41.92,44.98c-0.31,1.46-0.59,2.89-0.85,4.3H44.44v30.27H74.7v30.26
			h30.27v-30.26h30.27v-14.89H139.45z"
          />
          <rect x="165.5" y="170.91" width="30.27" height="30.27" />
          <path
            d="M305.54,170.91c-0.26-1.41-0.54-2.84-0.85-4.3c-3.78-17.69-31.43-30.21-41.92-44.98c-3.92-6.37-7.18-12.85-6.97-22.33
			c0.28-13.15,7.73-16.61,7.73-16.61c0.86,12.42,10.78,12.95,14.49,22.31c5.7,14.38,0.13,20.49,0.13,20.49s7.87-1,20.56-1.23
			c12.69-0.23,11.81-10.03,11.81-10.03c-4.94,1.17-9.76-1.29-15.95-10.69c-7.78-11.82-1.2-21.05-1.2-21.05
			c26.61,3.52,22.21,20.33,22.21,20.33c8.49-3.4,8.8,7.84,8.8,7.84s21.83-7.9,22.7-21.09c0.31-4.62-8.99-6.68-17.41-10.78
			c-11.38-5.55-13.53-10.55-13.53-10.55c4.11-11.95-8.08-21.22-21.29-28.83c-13.06-7.52-18.45-25.24-18.57-25.24h-0.02
			c-12.09,17.46-1.54,27.46-1.54,27.46c-23.73-11.16-38.76-3.87-38.76-3.87l5.01,6.34c-4.01-0.46-8.24-0.14-12.78,1.36
			c8.46-17.22,21.7-21.08,21.7-21.08c-9.02-1.87-17.41-2.89-25.19-3.27l-19,37.02h2.03h3.82v7.62h-3.82H196.9l11.01,92.72h3.9h4.13
			v4.1c0,0,0,0.01,0,0.01v6.54c0,0,0,0.01,0,0.01V171h0.64h4.77v4.73v7.55v3h0.44h4.23v14.89h30.27v30.26h30.26v-30.26h30.27v-30.27
			H305.54z"
          />
          <rect x="135.23" y="201.18" width="30.26" height="30.26" />
          <rect x="195.76" y="201.18" width="30.26" height="30.26" />
          <rect x="316.83" y="201.18" width="30.27" height="30.26" />
          <rect x="14.17" y="201.18" width="30.26" height="30.26" />
        </g>
        <g>
          <path
            d="M25.76,313.16l7.76-2.38v-46.46l-9.11,0.83l-1.24,12.21h-7.66l-1.35-21.21H68.4l-1.34,21.21h-7.66l-1.24-12.21l-9.11-0.83
			v46.25l7.97,2.28v6.21H25.76V313.16z"
          />
          <path
            d="M67.15,313.16l6.42-2.07l17.07-54.33c2.76-0.41,5.74-0.78,8.95-1.09c3.21-0.31,5.5-0.53,6.88-0.67
			c0.21,0,0.9,0.38,2.07,1.14l17.38,54.95l6.42,2.07v5.9h-29.7v-6.62l7.04-1.86l-2.9-10.55H89.2l-2.48,10.55l7.24,1.86v6.62h-26.8
			V313.16z M104.61,292.05l-6.11-24.01h-1.14l-6.11,24.01H104.61z"
          />
          <path
            d="M132.45,332.2c-2.28-0.48-4.17-0.86-5.69-1.14v-7.24c6.42,0,10.14-0.1,11.18-0.31c2.21-0.35,3.31-4.24,3.31-11.69v-47.39
			l-6.73-2.38v-5.9h28.87v5.9l-6.73,2.38v49.57c0,2.35-1.05,5.05-3.16,8.12c-2.11,3.07-4.5,5.74-7.19,8.02
			c-2.69,2.28-4.66,3.41-5.9,3.41C139.17,333.54,136.52,333.09,132.45,332.2z"
          />
          <path
            d="M168.77,313.16l6.31-2.38v-46.36l-6.31-2.38v-5.9h45.74l-1.35,19.87h-7.66l-1.24-12.52l-13.76,1.45v19.25l15.21,0.52v7.66
			l-15.21,0.62v16.97l10.35,2.79v6.31h-32.08V313.16z"
          />
          <path
            d="M228.89,315.17c-2.83-3.35-4.24-8.6-4.24-15.78v-34.98l-6.31-2.38v-5.9h27.53v6.62l-6,2.48v31.15
			c0,4.48,0.52,7.43,1.55,8.85c1.03,1.41,2.55,2.12,4.55,2.12c1.52,0,3.76-0.4,6.73-1.19c2.97-0.79,4.69-1.26,5.17-1.4v-39.53
			l-6-2.48v-6.62h27.53v5.9l-6.31,2.38v46.36l6.31,2.38v5.9h-18.73l-2.28-6.21c-9.59,4.9-14.69,7.35-15.31,7.35
			C236.44,320.19,231.72,318.52,228.89,315.17z"
          />
          <path
            d="M322.64,319.06l-11.69-24.52c-2.42-4.69-4.83-10.8-7.24-18.32h-0.93l1.03,30.11v3.73l7.04,2.38v6.62h-25.35v-5.9
			l6.31-2.38v-46.36l-6.31-2.38v-5.9h24.01l13.45,28.77c1.52,3.1,3.38,8.28,5.59,15.52h0.93l-0.93-31.87v-3.41l-7.04-2.38v-6.62
			h25.25v5.9l-6.31,2.38v54.02c-2.07,0.21-4.8,0.5-8.17,0.88c-3.38,0.38-5.9,0.64-7.55,0.78
			C324.5,320.09,323.81,319.74,322.64,319.06z"
          />
        </g>
        <g>
          <path
            d="M17.66,386.56l-3.49-0.27l0.48-13.14h5.13l1.3,7.19c4.74,0.73,7.85,1.09,9.31,1.09c0.23,0,0.59-0.45,1.09-1.33
			c0.5-0.89,0.75-1.72,0.75-2.5c-0.05-1.6-0.66-2.94-1.85-4.04c-1.19-1.09-3.01-2.37-5.47-3.83c-2.24-1.32-4.04-2.5-5.41-3.52
			c-1.37-1.03-2.54-2.34-3.52-3.93c-0.98-1.6-1.47-3.47-1.47-5.61c0-1.19,0.11-2.33,0.34-3.42c0.55-1.05,1.68-2.29,3.39-3.73
			c1.71-1.44,3.48-2.69,5.3-3.76c1.82-1.07,3.17-1.61,4.04-1.61c0.78,0,2.58,0.15,5.41,0.45c2.83,0.3,5.47,0.63,7.94,0.99
			l-0.55,12.25h-5.13l-0.96-6.57c-4.79-0.82-7.73-1.23-8.83-1.23c-0.41,0-0.75,0.34-1.03,1.03c-0.27,0.68-0.41,1.57-0.41,2.67
			c0,1.64,0.56,3.07,1.68,4.28c1.12,1.21,2.73,2.43,4.82,3.66l1.44,0.82c2.24,1.32,4.03,2.48,5.37,3.46
			c1.35,0.98,2.49,2.2,3.42,3.66c0.93,1.46,1.4,3.17,1.4,5.13c0,1.23-0.14,2.4-0.41,3.49c-0.64,1.23-1.79,2.56-3.46,3.97
			c-1.67,1.41-3.38,2.61-5.13,3.59c-1.76,0.98-3.14,1.47-4.14,1.47C26.74,387.25,22.95,387.02,17.66,386.56z M18.35,332.71
			l2.94-2.74l6.98,4.86h0.62l6.91-4.86l3.01,2.74l-7.25,9.1h-6.02L18.35,332.71z"
          />
          <path
            d="M45.31,382.6l4.17-1.57v-30.66l-4.17-1.57v-3.9h18.34v4.38l-4.11,1.64v29.56l4.24,1.44v4.59H45.31V382.6z M70.83,382.94
			c-3.92-6.2-7.16-11.43-9.72-15.67v-3.15l9.92-13.48l-4.17-1.37v-4.38h17.79v3.9l-4.04,1.37l-10.61,13.89
			c1.37,2.01,3.42,4.93,6.16,8.76c2.74,3.83,4.74,6.57,6.02,8.21l3.7,1.44v4.04H73.02L70.83,382.94z"
          />
          <path
            d="M98.48,382.6l4.17-1.57v-30.66l-4.17-1.57v-3.9h19.37v4.04l-5,1.57v30.04l10.26,1.09l1.37-8.97h5.06l0.27,13.82H98.48
			V382.6z"
          />
          <path
            d="M126.19,397.44v-4.38c0.87,0,2.18-0.08,3.94-0.24c1.76-0.16,2.75-0.33,2.98-0.51c0.59-0.32,1-1.14,1.23-2.46
			c0.23-1.32,0.34-2.71,0.34-4.17v-25.12l-3.15-0.89v-3.83c5.93-0.87,9.19-1.3,9.79-1.3c0.23,0,0.74,0.39,1.54,1.16
			c0.8,0.78,1.2,1.3,1.2,1.57v30.8c0,1.32-0.57,2.85-1.71,4.58c-1.14,1.73-2.45,3.24-3.94,4.52c-1.48,1.28-2.61,1.92-3.39,1.92
			C133.93,399.09,130.99,398.54,126.19,397.44z M134.61,342.01l8.08-0.41l1.44,1.78l-0.55,6.64h-8.35L134.61,342.01z"
          />
          <path
            d="M153.84,384.89c-1.73-1.57-2.6-4.3-2.6-8.18V360.7l-2.74-1.03v-3.76c5.52-0.91,8.53-1.37,9.03-1.37
			c0.27,0,0.87,0.38,1.78,1.13c0.91,0.75,1.37,1.24,1.37,1.47v19.5c0,2.6,0.91,3.9,2.74,3.9c0.36,0,1.82-0.62,4.38-1.85v-18
			l-2.67-1.03v-3.76c5.52-0.91,8.55-1.37,9.1-1.37c0.27,0,0.84,0.38,1.71,1.13c0.87,0.75,1.3,1.24,1.3,1.47v23.95l2.74,1.03v3.76
			c-5.61,0.91-8.71,1.37-9.31,1.37c-0.23,0-0.7-0.38-1.4-1.13c-0.71-0.75-1.06-1.24-1.06-1.47v-1.92c-1.23,0.96-2.58,1.95-4.04,2.98
			c-1.46,1.03-2.28,1.54-2.46,1.54C158.2,387.25,155.57,386.46,153.84,384.89z"
          />
          <path
            d="M192.27,386.39c-3.54-0.57-6.01-1.02-7.42-1.33v-37.3l-2.74-1.03v-3.76c1-0.18,2.74-0.46,5.2-0.82
			c2.46-0.36,3.92-0.55,4.38-0.55c0.23,0,0.72,0.38,1.47,1.13c0.75,0.75,1.13,1.24,1.13,1.47l-0.55,14.51l0.48,0.14
			c0.32-0.23,1.36-1.02,3.11-2.36c1.76-1.35,2.75-2.02,2.98-2.02c3.51,0,6.33,1.33,8.45,4c2.12,2.67,3.18,6.38,3.18,11.12
			c0,2.05-0.17,4.03-0.51,5.92c-0.34,1.89-0.76,3.39-1.27,4.48c-0.87,1.05-2.83,2.52-5.88,4.41c-3.06,1.89-5.06,2.84-6.02,2.84
			C197.8,387.25,195.8,386.96,192.27,386.39z M201.88,370.55c0-1.78-0.16-3.39-0.48-4.83c-0.32-1.44-0.73-2.55-1.23-3.35
			c-0.5-0.8-1-1.2-1.5-1.2c-0.27,0-1.73,0.66-4.38,1.98v17.45c1.05,0.59,2.16,1.11,3.32,1.54c1.16,0.43,1.95,0.56,2.36,0.38
			C201.24,381.93,201.88,377.94,201.88,370.55z"
          />
          <path
            d="M218.78,386.12c-0.87-0.75-1.3-1.27-1.3-1.54v-36.89l-2.81-1.03v-3.76c5.84-0.91,9.06-1.37,9.65-1.37
			c0.23,0,0.71,0.38,1.44,1.13c0.73,0.75,1.09,1.27,1.09,1.54v36.82l2.74,1.03v3.76c-5.52,0.96-8.55,1.44-9.1,1.44
			C220.22,387.25,219.65,386.87,218.78,386.12z"
          />
          <path
            d="M226.65,397.44v-4.38c0.87,0,2.18-0.08,3.93-0.24c1.76-0.16,2.75-0.33,2.98-0.51c0.59-0.32,1-1.14,1.23-2.46
			c0.23-1.32,0.34-2.71,0.34-4.17v-25.12l-3.15-0.89v-3.83c5.93-0.87,9.19-1.3,9.79-1.3c0.23,0,0.74,0.39,1.54,1.16
			c0.8,0.78,1.2,1.3,1.2,1.57v30.8c0,1.32-0.57,2.85-1.71,4.58c-1.14,1.73-2.45,3.24-3.93,4.52c-1.48,1.28-2.61,1.92-3.39,1.92
			C234.39,399.09,231.44,398.54,226.65,397.44z M235.07,342.01l8.08-0.41l1.44,1.78l-0.55,6.64h-8.35L235.07,342.01z"
          />
          <path
            d="M252.73,383.31c-2.1-2.62-3.15-6.28-3.15-10.98c0-2.01,0.17-3.99,0.51-5.95c0.34-1.96,0.74-3.49,1.2-4.59
			c0.87-1.09,2.83-2.59,5.88-4.48c3.06-1.89,5.06-2.84,6.02-2.84c0.45,0,2.43,0.27,5.92,0.82c3.49,0.55,6.01,0.94,7.56,1.16v24.64
			l2.74,1.03v3.76c-5.57,0.91-8.65,1.37-9.24,1.37c-0.23,0-0.72-0.38-1.47-1.13c-0.75-0.75-1.13-1.24-1.13-1.47v-1.98
			c-3.88,3.06-6,4.58-6.36,4.58C257.65,387.25,254.82,385.94,252.73,383.31z M267.23,378.56v-17.38c-0.91-0.5-1.89-0.97-2.94-1.4
			c-1.05-0.43-1.85-0.65-2.4-0.65c-0.73,0-1.29,0.98-1.68,2.94c-0.39,1.96-0.58,5.13-0.58,9.51c0,2.6,0.33,4.75,0.99,6.43
			c0.66,1.69,1.4,2.53,2.22,2.53C263.13,380.54,264.59,379.88,267.23,378.56z"
          />
          <path
            d="M286.09,386.12c-0.89-0.75-1.33-1.27-1.33-1.54v-23.95l-2.74-1.03v-3.76c5.61-0.87,8.74-1.3,9.38-1.3
			c0.23,0,0.7,0.38,1.4,1.13c0.71,0.75,1.06,1.24,1.06,1.47v1.85c1.19-0.91,2.53-1.88,4.04-2.91c1.51-1.03,2.37-1.54,2.6-1.54
			c3.47,0,6.08,0.79,7.83,2.36c1.76,1.57,2.64,4.28,2.64,8.11v16.01l2.74,1.03v3.76c-5.57,0.96-8.6,1.44-9.1,1.44
			c-0.27,0-0.86-0.38-1.75-1.13c-0.89-0.75-1.33-1.27-1.33-1.54v-19.5c0-2.55-0.94-3.83-2.81-3.83c-0.37,0-1.87,0.62-4.52,1.85
			v17.93l2.74,1.03v3.76c-5.52,0.96-8.55,1.44-9.1,1.44C287.56,387.25,286.98,386.87,286.09,386.12z"
          />
          <path
            d="M320.06,383.31c-2.1-2.62-3.15-6.28-3.15-10.98c0-2.01,0.17-3.99,0.51-5.95c0.34-1.96,0.74-3.49,1.2-4.59
			c0.87-1.09,2.83-2.59,5.88-4.48c3.06-1.89,5.06-2.84,6.02-2.84c0.45,0,2.43,0.27,5.92,0.82c3.49,0.55,6.01,0.94,7.56,1.16v24.64
			l2.74,1.03v3.76c-5.57,0.91-8.65,1.37-9.24,1.37c-0.23,0-0.72-0.38-1.47-1.13c-0.75-0.75-1.13-1.24-1.13-1.47v-1.98
			c-3.88,3.06-6,4.58-6.36,4.58C324.99,387.25,322.16,385.94,320.06,383.31z M334.57,378.56v-17.38c-0.91-0.5-1.89-0.97-2.94-1.4
			c-1.05-0.43-1.85-0.65-2.4-0.65c-0.73,0-1.29,0.98-1.68,2.94c-0.39,1.96-0.58,5.13-0.58,9.51c0,2.6,0.33,4.75,0.99,6.43
			c0.66,1.69,1.4,2.53,2.22,2.53C330.47,380.54,331.93,379.88,334.57,378.56z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
