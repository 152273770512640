import React from "react";
import styled from "styled-components";

import {
  device,
  contentWidth,
  paddingMedium,
  xMarginMobile,
} from "../../constants/variables";

const Panel = ({ className, backgroundColor, ...props }) => (
  <Wrapper className={className} backgroundColor={backgroundColor}>
    <Content>{props.children}</Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingMedium} 0;
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: auto;
  @media ${device.mobile} {
    margin: 0 ${xMarginMobile};
  }
`;

Panel.Wrapper = Wrapper;

export default Panel;
