import styled from "styled-components";

import { colors } from "../../constants/variables";

const Line = styled.div`
  height: 6px;
  width: 70px;
  background-color: ${({ light }) =>
    light ? colors.primaryLight : colors.primary};
`;

export default Line;
