import imageDesktop from "../assets/images/desktop.svg";
import imageCalendar from "../assets/images/calendar.svg";
import imageBadge from "../assets/images/badge.svg";
import imageGift from "../assets/images/gift.svg";
import imageUsers from "../assets/images/users.svg";

const content = [
  {
    id: 0,
    image: imageDesktop,
    text: "Šahovski webinarji",
  },
  {
    id: 1,
    image: imageCalendar,
    text: "Tedenska srečanja v klubu",
  },
  {
    id: 2,
    image: imageBadge,
    text:
      "PRIHAJA KMALU: Tedenski klubski turnirji s posebnimi nagradami za naše člane",
  },
  {
    id: 3,
    image: imageGift,
    text: "Ugodnosti pri prijavninah na turnirje, ki jih organizira naš klub",
  },
  {
    id: 4,
    image: imageUsers,
    text: "Možnost nastopa v naših ekipah",
  },
];

export default content;
