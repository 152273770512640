import React from "react";
import styled from "styled-components";

import {
  device,
  colors,
  paddingSmall,
  radius,
} from "../../constants/variables";

import BoxShadow from "../shared/BoxShadow";
import Button from "../shared/Button";
import Panel from "../shared/Panel";
import Title from "../shared/Title";

import image from "../../assets/images/lichess.png";
import messageIcon from "../../assets/images/info.svg";

const Tournaments = () => {
  return (
    <section id="turnirji">
      <Panel backgroundColor={colors.white}>
        <Content>
          <Image src={image} />
          <div>
            <Title>Tekmovanja</Title>
            <TextImportant>
              Vsako sredo ob 19:00 organiziramo turnirje na strani lichess.org.
            </TextImportant>
            <Text>
              Z našimi člani se vsako leto udeležujemo posamičnih in ekipnih državnih tekmovanj v različnih kategorijah, kjer dosegamo izvrstne rezultate. Poleg tega organiziramo tudi klubske in odprte mednarodne turnirje, ki pritegnejo šahiste iz celega sveta.
            </Text>
            <a
              href="https://lichess.org/team/tajfun-sahovski-klub-ljubljana"
              target="_blank"
              rel="noreferrer"
            >
              <StyledButton>Pridruži se turnirju</StyledButton>
            </a>
            <Message>
              <MessageIcon src={messageIcon} />
              <MessageText>
                Kmalu bomo začeli tudi s tedensko serijo turnirjev v ŽIVO.
                Podrobnosti sledijo v kratkem!
              </MessageText>
            </Message>
          </div>
        </Content>
      </Panel>
    </section>
  );
};

const Content = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  border-radius: ${radius};
  margin-right: ${paddingSmall};
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);
  object-fit: cover;

  @media ${device.mobile} {
    margin-top: ${paddingSmall};
    margin-right: 0;
    width: 100%;
    height: auto;
    max-width: 400px;
  }
`;

const TextImportant = styled.div`
  display: none;
  color: ${colors.primary};
  font-weight: 900;
  font-size: 20px;
  margin-bottom: ${paddingSmall};
`;

const Text = styled.div`
  margin-bottom: ${paddingSmall};
`;

const StyledButton = styled(Button)`
  display: none;
  @media ${device.mobile} {
    width: 100%;
    max-width: 400px;
  }
`;

const Message = styled(BoxShadow)`
  margin-top: ${paddingSmall};
  border: 1px solid ${colors.primaryLight};
  padding: 16px;
  display: flex;
  display: none;
`;

const MessageIcon = styled.img`
  height: 32px;
  margin-right: 16px;
`;

const MessageText = styled.div`
  font-weight: 900;
  font-size: 14px;
`;

export default Tournaments;
