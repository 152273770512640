import React from "react";
import styled from "styled-components";

import { contentWidthNarrow, colors } from "../../constants/variables";

import BoxShadowImage from "../shared/BoxShadowImage";

const TeamMember = ({ member }) => {
  return (
    <Wrapper>
      <StyledBoxShadowImage src={member.image} />
      <Name>{member.name}</Name>
      <Description>{member.description}</Description>
    </Wrapper>
  );
};

const Name = styled.div`
  margin-top: 16px;
  text-align: center;
  max-width: ${contentWidthNarrow};
  color: ${colors.primary};
  font-size: 20px;
  font-weight: 800;
`;

const Description = styled.div`
  margin-top: 16px;
  font-size: 12px;
  text-align: center;
`;

const StyledBoxShadowImage = styled(BoxShadowImage)`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 75px;
  object-position: 30% 30%;
`;

const Wrapper = styled.div`
  width: 300px;
  padding: 0 8px;
  background-color: ${colors.white};
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default TeamMember;
