export const colors = {
  black: "#000000",
  white: "#FFFFFF",
  primary: "#79944E",
  primaryLight: "#E5E8E1",
  primaryDark: "#6E874E",
  primaryLightHovered: "#C2C9B7",
};

export const fontSizes = {
  small: "16px",
  text: "24px",
  titleSmall: "32px",
  title: "40px",
  titleLarge: "48px",
};

export const paddingSmall = "24px";
export const paddingMedium = "72px";
export const paddingLarge = "100px";

export const xMarginMobile = "16px";

export const contentWidth = "980px";
export const contentWidthNarrow = "700px";
export const navigationHeight = "80px";

export const radius = "12px";

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobile: `(max-width: ${size.laptop})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
