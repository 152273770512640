import React from "react";
import styled from "styled-components";

import {
  device,
  colors,
  paddingSmall,
  radius,
} from "../../constants/variables";

import Panel from "../shared/Panel";
import Title from "../shared/Title";

import image from "../../assets/images/klub.jpg";

const ClubOffice = () => {
  return (
    <section id="kontakt">
      <Panel backgroundColor={colors.primaryLight}>
        <Content>
          <TextWrapper>
            <Title>Klubski prostori</Title>
            <TextImportant>Podhod Vilharjeve ceste, pisarna K1</TextImportant>
            <Text>
              Šahovski klub Ljubljana je 1. marca 2017 slavnostno odprl vrata
              novih klubskih prostorov v podhodu Vilharjeve ceste, pisarna K1. V
              lično opremljenem prostoru se odvijajo redna tedenska srečanja
              naših članov, šahovska šola in individualni treningi.
            </Text>
          </TextWrapper>
          <Image src={image} />
        </Content>
      </Panel>
    </section>
  );
};

const Content = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: calc(100% - 400px);

  @media ${device.mobile} {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 400px;
  max-height: 300px;
  margin-left: ${paddingSmall};
  border-radius: ${radius};
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);
  object-fit: cover;

  @media ${device.mobile} {
    margin-top: ${paddingSmall};
    margin-left: 0;
    width: 100%;
    max-width: 400px;
  }
`;

const Text = styled.div``;

const TextImportant = styled.div`
  color: ${colors.primary};
  font-weight: 900;
  font-size: 20px;
  margin-bottom: ${paddingSmall};
`;

export default ClubOffice;
