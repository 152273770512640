import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { device, colors, paddingSmall } from "../../constants/variables";

import BoxShadow from "../shared/BoxShadow";
import Panel from "../shared/Panel";

import content from "../../content/why-chess";

const WhyChess = () => {
  const shuffleArray = (array) => {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    let all_items = content;
    const shuffeled_items = shuffleArray(all_items);
    const size = 5;
    const selected_items = shuffeled_items.slice(0, size);
    setItems(selected_items);
  }, []);

  return (
    <Panel backgroundColor={"none"}>
      <Subtitle>Zakaj šah?</Subtitle>
      <Items>
        {items.map((item, index) => (
          <Item key={index}>
            <Image src={item.image} />
            <Text>{item.text}</Text>
          </Item>
        ))}
      </Items>
    </Panel>
  );
};

const Subtitle = styled.div`
  color: ${colors.white};
  font-weight: 900;
  font-size: 20px;
`;

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  margin-top: ${paddingSmall};
  @media ${device.mobileL} {
  }
`;

const Item = styled(BoxShadow)`
  min-height: 170px;
  padding: 16px;
  background-color: ${colors.white};

  @media ${device.mobileL} {
    width: 100%;
    max-width: 400px;
    min-height: auto;
  }
`;

const Text = styled.div`
  font-weight: 900;
  font-size: 12px;
`;

const Image = styled.img`
  height: 36px;
  margin-bottom: 16px;
`;

export default WhyChess;
