import React from "react";
import styled from "styled-components";
import { Parallax } from "react-parallax";
import { useMediaLayout } from "use-media";

import {
  device,
  contentWidth,
  contentWidthNarrow,
  xMarginMobile,
  navigationHeight,
  colors,
  fontSizes,
} from "../../constants/variables";

import Line from "../shared/Line";
import WhyChess from "./WhyChess";

import cover from "../../assets/images/cover-s.png";
import coverMobile from "../../assets/images/coverMobile-s.png";

const Cover = () => {
  const isMobile = useMediaLayout({ maxWidth: "1024px" });

  return (
    <section id="domov">
      <Parallax
        bgImage={isMobile ? coverMobile : cover}
        bgImageAlt="chess"
        strength={200}
      >
        <Wrapper>
          <Content>
            <Name>Tajfun - Šahovski klub Ljubljana</Name>
            <Line />
            <Description>
              Klub je nastal leta 2013. Ustanovili so ga trije šahovski
              velemojstri Duško Pavasović, Darja Kapš in Luka Lenič, ter Jonas
              Žnidaršič, z namenom, da se šahu v prestolnici povrne ugled in
              prepoznavnost, ki ga je nekoč že imel. Naš cilj je, da Ljubljana
              znova postane šahovsko mesto in da odločno pripomoremo k boljšemu
              razvoju slovenskega šaha.
            </Description>
          </Content>
          <WhyChess />
        </Wrapper>
      </Parallax>
    </section>
  );
};

const Wrapper = styled.div`
  margin-top: ${navigationHeight};
  width: 100%;
  min-height: 80vh;
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: auto;
  color: ${colors.white};

  @media ${device.mobile} {
    margin: 0 ${xMarginMobile};
  }
`;

const Name = styled.div`
  max-width: ${contentWidthNarrow};
  width: 100%;
  font-size: ${fontSizes.title};
  font-weight: 900;
  margin-top: 200px;
  margin-bottom: 20px;

  @media ${device.mobile} {
    font-size: ${fontSizes.titleSmall};
  }
`;

const Description = styled.div`
  margin-top: 20px;
  max-width: ${contentWidthNarrow};
  width: 100%;
`;

export default Cover;
