import React from "react";
import styled from "styled-components";

import {
  colors,
  paddingMedium,
  paddingSmall,
  device,
} from "../../constants/variables";
import contentJunior from "../../content/members-junior";
import image1 from "../../assets/images/ekipnoprvenstvo.jpg";
import image2 from "../../assets/images/mladinskaliga.jpg";

import BoxShadowImage from "../shared/BoxShadowImage";
import Panel from "../shared/Panel";
import TeamMember from "./TeamMember";
import Title from "../shared/Title";

const Team = () => {
  return (
    <StyledPanel backgroundColor={colors.white}>
      <Title center>Mladinski šah</Title>
      <MembersList>
        {contentJunior.map((member, index) => (
          <TeamMember member={member} key={index} />
        ))}
      </MembersList>
      <ImagesWrapper>
        <ImageWrapper>
          <Image src={image1} />
        </ImageWrapper>
        <ImageWrapper>
          <Image src={image2} />
        </ImageWrapper>
      </ImagesWrapper>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  padding-bottom: calc(${paddingMedium} - 24px);
`;

const MembersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.mobile} {
      flex-direction: column;
    }
  } 
`;

const ImageWrapper = styled.div`
  &:not(:first-child) {
    margin-left: 20px;

    @media ${device.mobile} {
      margin-left: 0;
    }
  } 
`;

const Image = styled(BoxShadowImage)`
  max-width: 600px;
  width: 100%;
  height: 100%;
  objet-fit: cover;

  @media ${device.mobile} {
    margin-top: ${paddingSmall};
    margin-right: 0;
    width: 100%;
    height: 100%;
    max-width: 400px;
  }
`;

export default Team;
