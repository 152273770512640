import React, { useState } from "react";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import "./Navigation.css";
import { HashLink } from "react-router-hash-link";

import { device } from "../../constants/variables";
import LogoMobile from "./LogoMobile";

const Navigation = ({ scrolled }) => {
  var width = window.innerWidth;
  var show = width < 1024 ? true : false;
  const props = {
    ...(!show && { customBurgerIcon: false }),
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <StyledLogo scrolled={scrolled} />
      <Menu
        right
        {...props}
        isOpen={menuOpen}
        onStateChange={(state) => handleStateChange(state)}
      >
        <Link
          to="/#ekipa"
          smooth
          scrolled={scrolled}
          onClick={() => closeMenu()}
        >
          Ekipa
        </Link>
        <Link
          to="/#webinarji"
          smooth
          scrolled={scrolled}
          onClick={() => closeMenu()}
        >
          Webinarji
        </Link>
        <Link
          to="/#turnirji"
          smooth
          scrolled={scrolled}
          onClick={() => closeMenu()}
        >
          Turnirji
        </Link>
        <Link
          to="/#clanstvo"
          smooth
          scrolled={scrolled}
          onClick={() => closeMenu()}
        >
          Postani član
        </Link>
        <Link
          to="/#sola"
          smooth
          scrolled={scrolled}
          onClick={() => closeMenu()}
        >
          Šahovska šola
        </Link>
        <Link
          to="/#kontakt"
          smooth
          scrolled={scrolled}
          onClick={() => closeMenu()}
        >
          Kontakt
        </Link>
      </Menu>
    </div>
  );
};

const StyledLogo = styled(LogoMobile)`
  display: none;
  @media ${device.mobile} {
    display: inline;
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 4;
    width: 80px;

    transition: 0.4s ease-out;

    opacity: ${({ scrolled }) => (scrolled ? "0" : "1")};
  }
`;

const Link = styled(HashLink)`
  font-weight: 700;
  cursor: pointer;
`;

export default Navigation;
