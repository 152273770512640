import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

import {
  device,
  colors,
  paddingSmall,
  paddingMedium,
} from "../../constants/variables";
import quotes from "../../content/quotes";

import Panel from "../shared/Panel";

import bg from "../../assets/images/quotes.jpg";
import quoteMarks from "../../assets/images/quotes-sign.svg";

const Quotes = () => {
  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    var currentIntervalId = setInterval(calculateNewQuoteIndex, 7000);
    setIntervalId(currentIntervalId);
  }, []);

  const calculateNewQuoteIndex = () => {
    setSelectedQuoteIndex(
      (prevSelectedQuoteIndex) => (prevSelectedQuoteIndex + 1) % quotes.length
    );
  };

  const handleCircleClick = (index) => {
    setSelectedQuoteIndex(index);
    clearInterval(intervalId);
    var currentIntervalId = setInterval(calculateNewQuoteIndex, 6000);
    setIntervalId(currentIntervalId);
  };

  return (
    <StyledPanel backgroundColor={colors.white}>
      <Content>
        {quotes.map((quote, index) => (
          <QuoteWrapper key={index} show={index === selectedQuoteIndex}>
            <Image src={quote.image}></Image>
            <TextWrapper>
              <QuoteImage src={quoteMarks}></QuoteImage>
              <Quote>{quote.quote}</Quote>
              <Name>{quote.name}</Name>
            </TextWrapper>
          </QuoteWrapper>
        ))}
      </Content>
      <Indicator>
        {quotes.map((_, index) => (
          <Circle
            key={index}
            selected={index === selectedQuoteIndex}
            onClick={() => handleCircleClick(index)}
          />
        ))}
      </Indicator>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const Content = styled.div`
  min-height: 230px;
  position: relative;

  @media ${device.mobile} {
    min-height: 620px;
  }
`;

const quoteInAnimation = keyframes`
 0% { opacity: 0;}
 100% { opacity: 1; }
`;

const quoteOutAnimation = keyframes`
 0% { opacity: 1;}
 100% { opacity: 0; }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  opacity: ${({ show }) => (show ? "1" : "0")};
  display: flex;
  animation-name: ${({ show }) =>
    show ? quoteInAnimation : quoteOutAnimation};
  ${quoteInAnimation};
  animation-duration: 1s;
  animation-iteration-count: initial;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-right: ${paddingSmall};
  border-radius: 100%;
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);

  @media ${device.mobile} {
    margin-bottom: ${paddingSmall};
    margin-right: 0;
  }
`;

const TextWrapper = styled.div`
  width: calc(100% - 200px);

  @media ${device.mobile} {
    width: 100%;
  }
`;

const QuoteImage = styled.img`
  margin-bottom: ${paddingSmall};
  height: 50px;
`;

const Name = styled.div`
  color: ${colors.primary};
  font-weight: 900;
`;

const Quote = styled.div`
  margin-bottom: ${paddingSmall};
  color: ${colors.white};
  font-size: 18px;
  font-weight: 700;
`;

const Indicator = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${paddingMedium};
`;

const Circle = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  border: 2px solid ${colors.white};
  background-color: ${({ selected }) => (selected ? colors.white : "none")};
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

export default Quotes;
