import React from "react";
import styled from "styled-components";

import { device, colors, fontSizes } from "../../constants/variables";

import Line from "./Line";

const Title = ({ center, light, ...props }) => (
  <Wrapper center={center}>
    <Text light={light}>{props.children}</Text>
    <Line light={light} />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  margin-bottom: 40px;
`;

const Text = styled.div`
  margin-bottom: 20px;
  font-size: ${fontSizes.title};
  font-weight: 800;
  line-height: 1em;
  color: ${({ light }) => (light ? colors.white : colors.black)};

  @media ${device.mobile} {
    font-size: ${fontSizes.titleSmall};
  }
`;

export default Title;
