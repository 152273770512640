import styled from "styled-components";
import React from "react";

import { colors } from "../../constants/variables";

const ButtonWithIcon = ({ className, icon, light, ...props }) => (
  <Button className={className} light={light}>
    <Content>
      <Icon src={icon} />
      <Text light={light}>{props.children}</Text>
    </Content>
  </Button>
);

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  margin-right: 12px;
`;

const Text = styled.div`
  color: ${({ light }) => (light ? colors.primary : colors.white)};
  font-weight: 900;
`;

const Button = styled.button`
  text-align: center;
  padding: 14px 24px;
  background-color: ${({ light }) => (light ? colors.white : colors.primary)};
  color: ${({ light }) => (light ? colors.primary : colors.white)};
  font-weight: 900;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  min-width: 200px;

  &:hover {
    background-color: ${({ light }) =>
      light ? colors.primaryLight : colors.primaryDark};
  }
`;

export default ButtonWithIcon;
