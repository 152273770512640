import React from "react";
import styled from "styled-components";

import {
  device,
  colors,
  paddingSmall,
  radius,
} from "../../constants/variables";

import Button from "../shared/Button";
import Panel from "../shared/Panel";
import Title from "../shared/Title";

import image from "../../assets/images/webinars.jpeg";

const Webinars = () => {
  return (
    <section id="webinarji">
      <Panel backgroundColor={colors.primaryLight}>
        <Content>
          <Image src={image}></Image>
          <div>
            <Title>Šahovski webinarji</Title>
            <Text>
            Enkrat mesečno pripravljamo informativne, poučne in zabavne šahovske vsebine, ki si jih lahko v družbi velemojstrice Darje Kapš ogledate v webinarju. Udeležba je brezplačna za vse šahovske navdušence.
            </Text>
            <a
              href="https://arnes-si.zoom.us/j/98887410422"
              target="_blank"
              rel="noreferrer"
            >
              <StyledButton>Zoom povezava</StyledButton>
            </a>
          </div>
        </Content>
      </Panel>
    </section>
  );
};

const Content = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-right: ${paddingSmall};
  border-radius: ${radius};
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);

  @media ${device.mobile} {
    margin-top: ${paddingSmall};
    margin-right: 0;
    width: 100%;
    height: auto;
    max-width: 400px;
  }
`;

const Text = styled.div`
  margin-bottom: ${paddingSmall};
`;

const StyledButton = styled(Button)`
  @media ${device.mobile} {
    width: 100%;
    max-width: 400px;
  }
`;

export default Webinars;
