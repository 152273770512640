import imageAna from "../assets/images/anasrebrnic.jpg";
import imageLaura from "../assets/images/lauraunuk.jpg";
import imageDarja from "../assets/images/darjakaps.jpg";
import imagePetra from "../assets/images/petrakejzar.png";
import imageTeja from "../assets/images/tejavidic.jpg";
import imageZala from "../assets/images/zalaurh.jpg";
import imageJK from "../assets/images/jana.jpg";

const content = [
  {
    id: 0,
    image: imageTeja,
    name: "Teja Vidic",
    description:
      "Teja Vidic (l. 1998) je mednarodna mojstrica od leta 2020. V ženski reprezentanci Slovenije je nastopila na dveh šahovskih olimpijadah leta 2016 in 2018 in na dveh ekipnih evropskih prvenstvih 2017 in 2019. Odlične rezultate je dosegla v l. 2017 z osvojitvijo naslova posamične članske državne prvakinje in s šestim najboljšim izidom na 4. šahovnici na ekipnem EP 2017 na Kreti. V mladinski konkurenci ima ekipno medaljo na evropskem prvenstvu za dekleta do 18 let leta 2013 v Mariboru. Tejo in njeni prijateljici Laro Janželj in Lauro Unuk, lahko redno spremljate na zabavnem kanalu Twitch: ",
    link: true,
  },
  {
    id: 1,
    image: imageLaura,
    prefix: "velemojstrica",
    name: "Laura Unuk",
    description:
      "Laura Unuk, najboljša slovenska šahistka, je dvakratna svetovna mladinska prvakinja in sicer v kategoriji do 16 let (2014) in do 18 let (2017). Leta 2014 je prejela Bloudkovo plaketo za pomemben tekmovalni dosežek v športu. Skupaj je osvojila 10 medalj na mladinskih evropskih in svetovnih prvenstvih v standardnem, pospešenem in hitropoteznem šahu. Naziv velemojstrice je dosegla leta 2018, ko se je na EP za članice uvrstila na 16. mesto in izpolnila zadnji pogoj za najvišji naslov. Že s 13 leti je postala državna članska prvakinja (2013) in od takrat uspešno nastopa za slovensko žensko reprezentanco. Lauro in njeni prijateljici Tejo Vidic in Laro Janželj, lahko redno spremljate na zabavnem kanalu Twitch:",
    link: true,
  },
  {
    id: 2,
    image: imageZala,
    name: "Zala Urh",
    description:
      "Zala Urh (l. 2002) je mednarodna mojstrica od leta 2020. V l. 2019 je na svetovnem prvenstvu za mladinke do 18 let v Indiji delila 3.-6. mesto in bila izbrana za najboljšo mladinko v Sloveniji. Osvojila je 6 medalj na EP in SP za mlade v posamični in ekipni konkurenci: 1. mesto ekipno EP za mladinke do 18 let v pospešenem šahu (Romunija, 2018), 2. mesto na SP za dekleta do 18 let v pospešenem šahu (Španija, 2019), 2. mesto na EP za dekleta do 18 let v hitropoteznem šahu (Estonija, 2019), 2. mesto ekipno EP za mladinke do 18 let (Poljska, 2018), 3. mesto EP v pospešenem šahu za dekleta do 16 let (Črna gora, 2017), 3. mesto ekipno EP v pospešenem šahu do 14 let (Srbija, 2016)",
  },
  {
    id: 3,
    image: imageAna,
    prefix: "velemojstrica",
    name: "Ana Srebrnič",
    description:
      "Šahovska velemojstrica in večkratna državna prvakinja. Nekdaj, je bila Ana ena izmed nosilk ženske članske reprezentance, ki je na 37. Šahovski olimpijadi v Torinu dosegla izjemno 9. mesto, kar je eden izmed največjih uspehov izbrane vrste. V zadnjih letih je Ana postala mednarodno renomirana šahovska sodnica, poleg tega, pa je ena izmed učiteljic v klubski šahovski šoli Zmajček.",
  },
  {
    id: 4,
    image: imageDarja,
    prefix: "velemojstrica",
    name: "Darja Kapš",
    description:
      "Je šahovska velemojstrica in večletna članica slovenske reprezentance ter nosilka dveh kolajn iz ekipnih evropskih članskih šahovskih prvenstev (2001 v Španiji in 2003 v Bolgariji). Je organizatorka večih mednarodnih šahovskih turnirjev in šahovskih kolonij, ambasadorka šaha in velika zagovornica enakopravnosti žensk v šahu. Leta 2019 je postala podpredsednica Šahovske zveze Slovenije.",
  },
  {
    id: 5,
    image: imagePetra,
    name: "Petra Kejžar",
    description:
      "Petra Kejžar (l. 1996) je fide mojstrica od leta 2021. Osvojila je dve normi za mednarodno mojstrico. Poleg rednega udeleževanja na mednarodnih turnirjih je eno leto preživela v Dubaju, kjer  je otroke učila šah v tamkajšnjem šahovskem klubu. S šahovskim treniranjem pa nadaljuje tudi v Sloveniji.",
  },
  {
    id: 6,
    image: imageJK,
    name: "dr. Jana Krivec",
    description:
      "Jana Krivec je šahovska velemojstrica in doktorica znanosti. Kot edina šahistka se ponaša s sedemkratnim naslovom članske državne prvakinje v šahu. Z žensko člansko reprezentanco je nastopila na 12 šahovskih olimpijadah in številnih evropskih prvenstvih ter se lahko pohvali z najdaljšim (22-letnim) stažem v slovenski reprezentanci. Na čelu slovenske ženske rating lestvice je bila vse do 2017, medtem ko je bil njen najvišji rating 2362. Sodelovala je na petih ženskih Mitropa pokalih, kjer je z ekipo v Saint-Vincentu leta 2002 in v Rogaški Slatini leta 2009 osvojila zlato. Na ekipnih prvenstvih je praviloma dosegala zavidljive rezultate, še posebej gre izpostaviti osvojitev bronaste kolajne med rezervistkami na ekipnem EP v Batumiju (1999).",
  },
];

export default content;
