import React from "react";
import styled from "styled-components";

import {
  device,
  colors,
  paddingSmall,
  radius,
} from "../../constants/variables";

import Button from "../shared/Button";
import Panel from "../shared/Panel";
import Title from "../shared/Title";

import bg from "../../assets/images/bg_school.png";
import image from "../../assets/images/zmajcek.jpg";

const School = () => {
  return (
    <section id="sola">
      <StyledPanel backgroundColor={colors.white}>
        <Content>
          <Image src={image}></Image>
          <div>
            <Title>Šahovska šola Zmajček</Title>
            <Text>
              V šahovski šoli Zmajček skozi šahovsko igro razvijamo in
              spodbujamo vrsto sposobnosti, ki se med seboj povezujejo in
              delujejo vzajemno. Otroci ob tem uživajo, hkrati pa razvijajo
              pomembne veščine, ki so nujno potrebne za uspešno krmarjenje na
              poti življenja.
            </Text>
            <a
              href="http://www.sah-zmajcek.si/"
              target="_blank"
              rel="noreferrer"
            >
              <StyledButton>Spletna stran</StyledButton>
            </a>
          </div>
        </Content>
      </StyledPanel>
    </section>
  );
};

const StyledPanel = styled(Panel)`
  background-image: url(${bg});
  background-size: 250px;
`;

const Content = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column-reverse;
  }
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  padding: 12px;
  background-color: ${colors.white};
  margin-right: ${paddingSmall};
  border-radius: ${radius};
  box-shadow: 5px 5px 18px rgb(27, 5, 107, 0.1);

  @media ${device.mobile} {
    margin-top: ${paddingSmall};
    margin-right: 0;
    width: 100%;
    height: auto;
    max-width: 400px;
  }
`;

const StyledButton = styled(Button)`
  @media ${device.mobile} {
    width: 100%;
    max-width: 400px;
  }
`;

const Text = styled.div`
  margin-bottom: ${paddingSmall};
`;

export default School;
