import React from "react";
import styled from "styled-components";

import { device, colors, paddingSmall } from "../../constants/variables";

import BoxShadow from "../shared/BoxShadow";
import Panel from "../shared/Panel";

import items from "../../content/perks";

const MemberPerks = () => {
  return (
    <Panel backgroundColor={colors.white}>
      <Subtitle>Ugodnosti za člane</Subtitle>
      <Items>
        {items.map((item, index) => (
          <Item key={index}>
            <Image src={item.image} />
            <Text>{item.text}</Text>
          </Item>
        ))}
      </Items>
    </Panel>
  );
};

const Subtitle = styled.div`
  color: ${colors.black};
  font-weight: 900;
  font-size: 20px;
`;

const Items = styled.div`
  margin-top: ${paddingSmall};
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
`;

const Item = styled(BoxShadow)`
  min-height: 170px;
  padding: 16px;
  background-color: ${colors.primaryLight};

  @media ${device.mobileL} {
    width: 100%;
    max-width: 400px;
    min-height: auto;
  }
`;

const Text = styled.div`
  font-weight: 900;
  font-size: 12px;
`;

const Image = styled.img`
  height: 36px;
  margin-bottom: 16px;
`;

export default MemberPerks;
