import styled from "styled-components";

import { colors } from "../../constants/variables";

const Button = styled.button`
  text-align: center;
  padding: 14px 24px;
  background-color: ${({ light }) => (light ? colors.white : colors.primary)};
  color: ${({ light }) => (light ? colors.primary : colors.white)};
  font-weight: 900;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  min-width: 200px;

  &:hover {
    background-color: ${({ light }) =>
      light ? colors.primaryLight : colors.primaryDark};
  }
`;

export default Button;
