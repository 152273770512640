import React from "react";
import styled from "styled-components";

import { device, colors, paddingSmall } from "../../constants/variables";

import BoxShadow from "../shared/BoxShadow";
import BoxShadowImage from "../shared/BoxShadowImage";
import Panel from "../shared/Panel";
import Title from "../shared/Title";

import image1 from "../../assets/images/tajfun1.jpg";
import image2 from "../../assets/images/tajfun2.png";
import image3 from "../../assets/images/tajfun3.jpg";
import imageMol from "../../assets/images/mol.jpeg";
import imageEles from "../../assets/images/eles.jpg";
import imageSZ from "../../assets/images/sponzorji/slovenske-zeleznice_logo.jpg";
import imageBIT from "../../assets/images/sponzorji/BIT-Center-logo.png";
import imageFAS from "../../assets/images/sponzorji/FASCIKEL-koncni1.jpeg";
import imageSM from "../../assets/images/sponzorji/studiomazzini-logo.jpg";

const Sponsors = () => {
  return (
    <Panel backgroundColor={colors.white}>
      <Title>Sponzorji</Title>
      <Subtitle>Generalni sponzor</Subtitle>
      <Subsubtitle>Tajfun Planina d.o.o.</Subsubtitle>
      <Text>
        Na sedežu podjetja Tajfun Planina d.o.o. je bila v oktobru 2020
        podpisana sponzorska pogodba, s čimer je ljubljanski klub poleg novega
        imena ŠK Tajfun Ljubljana dobil tudi osveženo vizijo z jasnimi in
        visokimi cilji.
      </Text>
      <Text>
        Podjetje Tajfun Planina d.o.o. je bilo ustanovljeno leta 1967 in ima
        nadvse dolgo in uspešno tradicijo. S prodajno mrežo v več kot 50 državah
        po vsem svetu, učinkovitim servisom in poprodajno podporo zagotavlja
        celovite rešitve na področju gozdarske mehanizacije. Z najsodobnejšimi
        stroji, vrhunsko tehnologijo, računalniško nadzorovanimi sistemi,
        uporabo laserskih rezalnikov in senzorskih orodij je podjetje razvilo
        napreden proizvodni proces. Njihov cilj je ustvarjati zmogljive in varne
        gozdarske stroje, prijazne uporabnikom in podprte z gozdarskim
        informacijskim sistemom ter jim tako olajšati delo v gozdu ter
        zagotoviti celovito in konkurenčno rešitev za zadostitev potreb v
        gozdarski logistiki.  Direktor podjetja Iztok Špan je velik ljubitelj in
        podpornik športa. Veseli nas, da je prav šah izbran kot tista športna
        disciplina, s katero se podjetje Tajfun Planina d.o.o. lahko
        poistoveti.  Prav v šahu je vodstvo podjetja poleg tekmovalnih izzivov
        prepoznalo tudi povezavo s podjetništvom, visoko tehnologijo,
        psihofizično kondicijo, izobraževanjem, vztrajnostjo in usmerjenostjo k
        napredku.
      </Text>
      <a href={"https://www.tajfun.si/"} target="_blank" rel="noreferrer">
        <Link>Spletna stran</Link>
      </a>
      <ImagesWrapper>
        <StyledBoxShadowImage src={image1} />
        <StyledBoxShadowImage src={image2} />
        <StyledBoxShadowImage src={image3} />
      </ImagesWrapper>
      <OtherSponsors>
        <Subtitle>Ostali sponzorji</Subtitle>
        <OtherSponsorsContent>
          <ImageOtherSponsorWrapper>
            <ImageOtherSponsors src={imageMol} />
          </ImageOtherSponsorWrapper>
          <ImageOtherSponsorWrapper>
            <ImageOtherSponsors src={imageEles} />
          </ImageOtherSponsorWrapper>
          <ImageOtherSponsorWrapper>
            <ImageOtherSponsors src={imageSZ} />
          </ImageOtherSponsorWrapper>
        </OtherSponsorsContent>
        <OtherSponsorsContent>
        <ImageOtherSponsorWrapper>
            <ImageOtherSponsors src={imageBIT} />
          </ImageOtherSponsorWrapper>
          <ImageOtherSponsorWrapper>
            <ImageOtherSponsors src={imageFAS} />
          </ImageOtherSponsorWrapper>
          <ImageOtherSponsorWrapper>
            <ImageOtherSponsors src={imageSM} />
          </ImageOtherSponsorWrapper>
        </OtherSponsorsContent>
      </OtherSponsors>
    </Panel>
  );
};

const Subtitle = styled.div`
  color: ${colors.primary};
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 12px;
`;

const Subsubtitle = styled.div`
  font-weight: 900;
  font-size: 28px;
  margin-bottom: ${paddingSmall};
`;

const Text = styled.div`
  margin-bottom: ${paddingSmall};
`;

const Link = styled.div`
  color: ${colors.primary};
  text-decoration: underline;
  font-weight: 700;

  &:hover {
    color: ${colors.primaryDark};
  }
`;

const ImagesWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const StyledBoxShadowImage = styled(BoxShadowImage)`
  height: 220px;
  max-width: 300px;
  object-fit: cover;

  @media ${device.mobile} {
    height: 250px;
    max-width: 400px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

const OtherSponsors = styled.div`
  margin-top: 60px;
`;

const OtherSponsorsContent = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const ImageOtherSponsorWrapper = styled(BoxShadow)`
  margin-top: ${paddingSmall};
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 24px;

  &:not(:first-child) {
    margin-left: ${paddingSmall};
  }

  @media ${device.mobile} {
    width: 100%;

    &:not(:first-child) {
      margin-left: 0;
    }
  }
`;

const ImageOtherSponsors = styled.img`
  margin-top: 8px;
  padding: 12px 0;
  width: 250px;
`;

export default Sponsors;
