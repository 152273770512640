import React from "react";
import styled from "styled-components";

import { device, colors } from "../../constants/variables";

import Panel from "../shared/Panel";

import facebook from "../../assets/images/logo-facebook.svg";
import instagram from "../../assets/images/logo-instagram.svg";
import mail from "../../assets/images/mail.svg";
import twitter from "../../assets/images/logo-twitter.svg";

const Footer = () => {
  return (
    <Panel backgroundColor={colors.primary}>
      <ContentGroup>
        <Text>
          Tajfun - Šahovski klub Ljubljana
          <br />
          Glavarjeva 16
          <br />
          1000 Ljubljana <br />
          Slovenija <br />
          <br />
          IBAN
          <br />
          SI56 6100 0002 6825 115
        </Text>
        <Text>
          PREDSEDNIK
          <br />
          velemojster Luka Lenič
        </Text>
        <Text>
          Kontakt
          <br />
          Darja Kapš
          <br />
          031 321 518
        </Text>
      </ContentGroup>
      <Line />
      <ContentGroup>
        <Text>
          <Social>
            <Link
              href={
                "https://www.facebook.com/Tajfun-%C5%A0K-Ljubljana-658674184243082/"
              }
              target="_blank"
            >
              <Item>
                <Image src={facebook} />
              </Item>
            </Link>
            <Link href={"https://twitter.com/sahljubljana"} target="_blank">
              <Item>
                <Image src={twitter} />
              </Item>
            </Link>
            <Link
              href={"https://www.instagram.com/sahljubljana"}
              target="_blank"
            >
              <Item>
                <Image src={instagram} />
              </Item>
            </Link>
          </Social>
        </Text>
        <Text>
          <Link href={"mailto:sah.ljubljana@gmail.com"} target="_blank">
            <div>
              <Image src={mail} />
            </div>
            sah.ljubljana@gmail.com
          </Link>
        </Text>

        <Text>
          <Link
            href={"mailto:sah.ljubljana.racunovodstvo@gmail.com"}
            target="_blank"
          >
            <div>
              <Image src={mail} />
            </div>
            sah.ljubljana.racunovodstvo@gmail.com
          </Link>
        </Text>
      </ContentGroup>
    </Panel>
  );
};

const Text = styled.div`
  flex: 0.33;
  font-weight: 700;

  &:not(:first-child) {
    padding-left: 20px;
  }

  @media ${device.mobile} {
    &:not(:first-child) {
      padding-top: 20px;
      padding-left: 0;
    }
  }
`;

const ContentGroup = styled.div`
  display: flex;
  color: ${colors.white};
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 3px;
  opacity: 0.2;
  background-color: ${colors.primaryLight};
  margin: 40px 0;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.white};
  font-weight: 700;

  &:hover {
    color: ${colors.primaryLight};
  }
`;

const Item = styled.div`
  margin-right: 12px;
`;

const Image = styled.img`
  height: 30px;
`;

const Social = styled.div`
  display: flex;
`;

export default Footer;
