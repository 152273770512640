import imageMatej from "../assets/images/matejsebenik.jpg";
import imageLuka from "../assets/images/lukalenic.jpg";
import imageDusko from "../assets/images/quote-dusko.jpg";
import imageLaura from "../assets/images/quote-laura.jpg";
import imageJonas from "../assets/images/quote-jonas.jpg";
import imageDarja from "../assets/images/quote-darja.jpg";
import imageBojan from "../assets/images/quote-bojan.jpg";
import imageDusan from "../assets/images/quote-dusan.jpg";
import imageIztok from "../assets/images/quote-iztok.jpg";
import imageMilan from "../assets/images/quote-milan.jpg";

const content = [
  {
    id: 0,
    image: imageDusko,
    name: "Velemojster Duško Pavasović, predsednik ŠK Ljubljana",
    quote:
      "Šah je življenje v malem. Kar se dogaja v življenju, se kaže na šahovnici.",
  },
  {
    id: 1,
    image: imageLuka,
    name: "Velemojster Luka Lenič, najboljši slovenski igralec",
    quote:
      "Šahovska igra te že zgodaj v življenju nauči, da je le to sestavljeno iz zmag in porazov ter vzponov in padcev.",
  },
  {
    id: 2,
    image: imageMatej,
    name: "Velemojster Matej Šebenik",
    quote:
      "Šah je igra, ki združuje komponente športa, umetnosti in znanosti ter s tem spodbuja človekov vsestranski razvoj.",
  },
  {
    id: 3,
    image: imageLaura,
    name: "Velemojstrica Laura Unuk",
    quote:
      "Šahist mora skozi vsako igro, ki jo igra, uporabljati možgane v tem smislu, da dogodke predvidi vnaprej. To je zelo dober trening za možgane, saj se s šahovske partije to prenese tudi v vsakdanje življenje in nam omogoča, da dogodke povezane s službo, financami ali šolo predvidimo vnaprej...",
  },
  {
    id: 4,
    image: imageJonas,
    name:
      "Jonas Žnidaršič, igralec, šahovski ljubitelj in častni član ŠK Ljubljana",
    quote: "Šah je lušten, je zanimiv, in je napet.",
  },
  {
    id: 5,
    image: imageDarja,
    name: "Velemojstrica Darja Kapš",
    quote:
      "Šah je najboljša vadba za možgane! Obenem je igranje šaha zelo zabavno, saj spoznavaš nove prijatelje, odkrivaš nove kraje in kulture.",
  },
  {
    id: 6,
    image: imageBojan,
    name: "Bojan Peršl, podpredsednik Šahovskega kluba Ljubljana",
    quote:
      "Tako kot pri šahu je tudi v življenju. Če si uspešen v šahu, si uspešen tudi v življenju. Zmage so sladke in se jih veselimo, od porazov pa se kaj naučimo.",
  },
  {
    id: 7,
    image: imageDusan,
    name: "Dušan Borštar",
    quote:
      "V mladosti te šah nauči, da z manj napakami lahko premagaš nasprotnika in napreduješ. Z leti pa šah postane tisti del življenja, v katerem si lahko človek kakšno napako privošči tudi brez hujših posledic.",
  },
  {
    id: 8,
    image: imageIztok,
    name: "Iztok Špan",
    quote:
      "V zadnjem času je šah spet v velikem vzponu, saj premore vse, kar najbolj prestižna športna tekmovanja premorejo: strategijo, vzdržljivost, iznajdljivost, potrpežljivost, nepopustljivost in čustva. In vse to ostaja zelo človeško.",
  },
  {
    id: 9,
    image: imageMilan,
    name: "g. Kneževič Milan, častni predsednik ŠK Ljubljana",
    quote:
      "Slovenija je dežela z dolgo in uspešno šahovsko tradicijo. Vesel sem, da se je po nekaj letih premora šah vrnil tudi v prestolnico in da sem kot častni predsednik kluba lahko del te uspešne šahovske zgodbe. Klubu želim vse dobro tudi v prihodnje.",
  },
];

export default content;
