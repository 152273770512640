import imageCube from "../assets/images/cube.svg";
import imageTrophy from "../assets/images/trophy.svg";
import imageNetwork from "../assets/images/network.svg";
import imageUpdate from "../assets/images/update.svg";
import imageLightBulb from "../assets/images/light-bulb.svg";
import imagePencil from "../assets/images/pencil.svg";
import imageFlag from "../assets/images/flag.svg";
import imageEducation from "../assets/images/education.svg";
import imageLink from "../assets/images/link.svg";
import imageUsers from "../assets/images/users.svg";

const content = [
  {
    id: 0,
    image: imageCube,
    text: "Izboljšuje prostorsko predstavo in vizualizacijo",
  },
  {
    id: 1,
    image: imageTrophy,
    text: "Uči, da je uspeh nagrajen s trdim delom",
  },
  {
    id: 2,
    image: imageNetwork,
    text: "Povečuje samozavest in sposobnost odločanja",
  },
  {
    id: 3,
    image: imageUpdate,
    text: "Uči potrpežljivosti",
  },
  {
    id: 4,
    image: imageLightBulb,
    text: "Je odličen za urjenje spomina",
  },
  {
    id: 5,
    image: imagePencil,
    text: "Spodbuja ustvarjalnost",
  },
  {
    id: 6,
    image: imageFlag,
    text: "Spodbuja planiranje v naprej",
  },
  {
    id: 7,
    image: imageEducation,
    text: "Uči predvideti posledice in prevzemanje odgovornosti zanje",
  },
  {
    id: 8,
    image: imageLink,
    text: "Povezuje generacije",
  },
  {
    id: 9,
    image: imageUsers,
    text: "Spodbuja interakcijo med posamezniki, pripomore k socializaciji",
  },
];

export default content;
