import React from "react";
import styled from "styled-components";

import { colors, paddingMedium } from "../../constants/variables";
import contentMen from "../../content/members-men";
import contentWomen from "../../content/members-women";

import Line from "../shared/Line";
import Panel from "../shared/Panel";
import TeamMember from "./TeamMember";
import Title from "../shared/Title";

const Team = () => {
  return (
    <section id="ekipa">
      <StyledPanel backgroundColor={colors.primaryLight} id="ekipa">
        <Title center>Ekipa</Title>
        <MembersList>
          {contentWomen.map((member, index) => (
            <TeamMember member={member} key={index} />
          ))}
        </MembersList>
        <LineWrapper>
          <Line />
        </LineWrapper>
        <MembersList>
          {contentMen.map((member, index) => (
            <TeamMember member={member} key={index} />
          ))}
        </MembersList>
      </StyledPanel>
    </section>
  );
};

const StyledPanel = styled(Panel)`
  padding-bottom: calc(${paddingMedium} - 24px);
`;

const MembersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const LineWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export default Team;
