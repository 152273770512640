import imageRudi from "../assets/images/rudiolenikcampa.png";
import imageEma from "../assets/images/ema.jpg";
import imageLeonard from "../assets/images/leonard.jpg";
import imageLeo from "../assets/images/leoglavac.jpeg";
import imageDavid from "../assets/images/davidmarkus.jpeg";
import imageGalI from "../assets/images/galintihar.jpeg";
import imageSimon from "../assets/images/simon.png";
import imagePatrik from "../assets/images/patriksrebrnic.jpeg";
import imageKarolina from "../assets/images/karolinakosak.jpg";


const content = [
  {
    id: 0,
    image: imageLeonard,
    name: "Leonard Belyaletdinov",
    description: "",
  },
   {
    id: 1,
    image: imageEma,
    name: "Ema Jakša",
    description: "",
  },
   {
    id: 3,
    image: imageLeo,
    name: "Leo Glavač",
    description: "",
  },
  {
    id: 4,
    image: imageRudi,
    name: "Rudi Olenik Čampa",
    description: "",
  },
  
  {
    id: 5,
    image: imageKarolina,
    name: "Karolina Košak",
    description: "",
  },
   {
    id: 6,
    image: imageSimon,
    name: "Simon Trussevich",
    description: "",
  },
  {
    id: 7,
    image: imageGalI,
    name: "Gal Intihar Dolinar",
    description: "",
  },
  {
    id: 8,
    image: imagePatrik,
    name: "Patrik Srebrnič",
    description: "",
  },
  {
    id: 9,
    image: imageDavid,
    name: "David Markus",
    description: "",
  },
];

export default content;
