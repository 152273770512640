import imageMatej from "../assets/images/matejsebenik.jpg";
import imageLuka from "../assets/images/lukalenic.jpg";
import imageDusko from "../assets/images/duskopavasovic.jpg";
import imageJure from "../assets/images/jureborisek.jpg";
import imageVid from "../assets/images/viddobrovoljc.jpg";
import imageTim from "../assets/images/tim_rifelj.jpg";

const content = [
  {
    id: 0,
    image: imageMatej,
    prefix: "velemojster",
    name: "Matej Šebenik",
    description:
      "Matej Šebenik je velemojster od leta 2012 in selektor mladinske reprezentance. Leta 1994 je osvojil 2. mesto na Evropskem prvenstvu do 12 let v pospešenem šahu. Leta 2014 je postal državni prvak med člani. Že nekaj let je trener najboljše slovenske šahistke Laure Unuk.",
  },
  {
    id: 1,
    image: imageLuka,
    prefix: "velemojster",
    name: "Luka Lenič",
    description:
      "Luka Lenič je najboljši slovenski šahist. V mladinskih kategorijah je zasijal leta 2002 z naslovom svetovnega prvaka do 14 let. Skupaj je osvojil 4 medalje na evropskih in svetovnih prvenstvih za mlade. Od leta 2007, ko je postal velemojster, je tudi član A reprezentance, kjer se je večkrat izkazal z izjemnimi rezultati. Na olimpijadi v Bakuju je dosegel visok rating performance 2740, kar je bil sedmi najboljši rezultat na 2. šahovnici. Eden vrhov njegove kariere sega v leto 2017. Na evropskem prvenstvu se je uvrstil na svetovni pokal, kjer je v 2. kolu v izenačenem boju izgubil proti Fabianu Caruani šele v drugem podaljšku. Na ekipnem EP na Kreti je blestel na 1. šahovnici s petim najboljšim dosežkom in izvrstnim rating performancem 2747. Najvišji rating 2662 točk je zabeležil leta 2018, ko je bil 83. na svetovni lestvici. Posebej velja izpostaviti tudi zmago proti Magnusu Carlsenu v pospešenem tempu v Pro Chess League leta 2018.",
  },
  {
    id: 2,
    image: imageDusko,
    prefix: "velemojster",
    name: "Duško Pavasović",
    description:
      "Duško Pavasovič je velemojster od leta 1999. Vrsto let je bil član slovenske moške reprezentance, od leta 2012 pa do leta 2014 je bil tudi njen selektor. Je večkratni državni članski šahovski prvak in zmagovalec odprtih mednarodnih šahovskih turnirjev v Ljubljani in Novi Gorici. Leta 2007 je na evropskem članskem prvenstvu dosegel izjemno 4. mesto.",
  },
  {
    id: 3,
    image: imageJure,
    prefix: "velemojster",
    name: "Jure Borišek",
    description:
      "Jure Borišek je šahovski velemojster od leta 2009. Kot eden najboljših slovenskih šahistov je nepogrešljiv član slovenske reprezentance. Nastopil je na številnih olimpijadah in evropskih prvenstvih. Na blejski šahovski olimpijadi na 2002 je igral v B ekipi. Že naslednjega leta je postal državni članski prvak in debitiral v A reprezentanci, kjer je na EP v Plovdivu prispeval k 4. mestu naše izbrane vrste.  V mladinskih kategorijah se je najbolj izkazal v letu 2004, ko je v Turčiji postal evropski podprvak do 18 let. Z ekipo je mladinskem EP osvojil bronasto medaljo. Po osvojitvi velemojstrskega naziva se je posvetil študiju in doktoriral iz farmacije. V zadnjem obdobju nastopa precej manj, a še vedno dosega odlične rezultate. Na ekipnem EP v Batumiju 2019 je na 2. šahovnici dosegel šesti najboljši rezultat (rating performance 2729).",
  },
  {
    id: 4,
    image: imageVid,
    name: "Vid Dobrovoljc",
    description:
      "Vid Dobrovoljc (l. 2000) je mednarodni mojster (IM) od leta 2023 in velja za enega najobetavnejših šahistov v Sloveniji. Bil je stalni član kadetske in mladinske reprezentance, sedaj pa je član moške reprezentance. Ponaša se s številnimi naslovi mladinskega državnega prvaka, leta 2000 pa je osvojil tudi naslov državnega prvaka v članski konkurenci. V letu 2017 je osvojil evropsko medaljo, saj je bil 2. na evropskem prvenstvu mladincev do 18 let v hitropoteznem šahu.",
  },
  {
    id: 5,
    image: imageTim,
    name: "Tim Janželj",
    description:
      "Tim Janželj (l. 1994) je mednarodni mojster (IM) od leta 2020 in velja za pravega šahovskega samouka. Bil je član mladinske in članske reprezentance, v zadnjih letih pa je stalni član moške klubske ekipe.",
  },
];

export default content;
